import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { thePrimokoDiffernceFields } from "../../metadata";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  article: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  unordered: {
    paddingLeft: theme.spacing.unit * 3
  },
  list: {
    display: "list-item"
  }
});

const ThePrimokoDifference = ({ classes, frontmatter, location }) => (
  <Layout location={location}>
    <React.Fragment>
      <TabBar fields={thePrimokoDiffernceFields} />
      <div className={classes.root}>
        <div className={classes.subheading}>Why Primoko? We speak geek.</div>
        <div className={classes.paragraph}>
          If you search the web for "web development," "software development,"
          or "app development," you’ll find millions of companies plying their
          wares.
        </div>
        <div className={classes.paragraph}>
          We’re one of those millions, but here’s what makes us different.
        </div>
        <div className={classes.unordered}>
          {frontmatter.list.map((v, k) => (
            <Typography
              key={k}
              className={classNames(
                classes.paragraph,
                classes.space,
                classes.list
              )}
            >
              {v}
            </Typography>
          ))}
        </div>
        <Typography
          className={classNames(classes.paragraph, classes.space)}
          gutterBottom
        >
          {frontmatter.others}
        </Typography>
      </div>
    </React.Fragment>
  </Layout>
);

export default withStyles(styles)(ThePrimokoDifference);
