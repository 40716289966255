import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ThePrimokoDifference from "../../containers/the-primoko-difference";
import { graphql } from 'gatsby';

const styles = theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing.unit * 22,
      paddingRight: theme.spacing.unit * 22,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5
    }
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.mtitle
    },
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.title
    }
  },
  space: {
    marginTop: theme.spacing.unit * 4
  },
  space2: {
    marginTop: "40px"
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.mhero
    },
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.hero
    },
    width: "fit-content",
    color: "white",
    background: `linear-gradient(101deg, #4198ff, #6f73ff)`,
    padding: "10px"
  },
  list: {
    display: "list-item"
  },
  unordered: {
    paddingLeft: theme.spacing.unit * 3
  },
  block: {
    width: "260px",
    height: "206px",
    background: `linear-gradient(to right, #4198ff, #6f73ff)`
  },
  logo: {
    width: "153.5px",
    height: "31px"
  },
  blockade: {
    height: "210px"
  },
  readButton: {
    marginTop: "40px"
  },
  button: {
    width: "max-content"
  },
  gridItem: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: "40px"
    }
  }
});

const ThePrimokoDifferenceIndexPagePreviewTemplate = ({ data, classes }) => {
  const { frontmatter } = data.markdownRemark;

  return <ThePrimokoDifference frontmatter={frontmatter} />;
};

export default withStyles(styles)(ThePrimokoDifferenceIndexPagePreviewTemplate);

export const pageQuery = graphql`
  query ThePrimokoDifferenceIndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        heading
        description
        list
        others
        logo
      }
    }
  }
`;
